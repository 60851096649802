
import React,{ useEffect, useRef } from "react"
import ScrollToBottom from "react-scroll-to-bottom"

import "./Messages.css"
import Message from "../Message/Message"
//import sendIcon from "../../imgs/onlineIcon.png"




const Messages = ({ messages, name }) => {
    
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages]);

    return (
        <ScrollToBottom className="messages">
            {messages.map((message, i) => <div key={i}><Message message={message} name={name} /></div>)}
            <div ref={messagesEndRef} />
        </ScrollToBottom>
    )

}




export default Messages