import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Externas from '../pages/_layouts/Externas';
import Internas from '../pages/_layouts/Internas';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isChat = false,
  ...rest
}) {
  const logged = localStorage.getItem('mac_logged');
  const token = localStorage.getItem('mac_token');


  if (!logged && isPrivate) {
    return <Redirect to="/" />;
  }

  if ((logged && token) && !isPrivate && !isChat) {
    return <Redirect to="/dashboard" />;
  }

  const Layout = logged ? Internas : Externas;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}
