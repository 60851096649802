import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '../routes/Route';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Chat from "../pages/Chat"
import Join from '../pages/Join'
import SRIChat from '../pages/SRIChat'
import StartSRIChat from '../pages/StartSRIChat'
import Avaliacao from '../pages/Avaliacao'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/chat" component={Chat} isPrivate/>
      <Route path="/sri" component={SRIChat}/>
      <Route path='/start' component={StartSRIChat}/>
      {/* <Route path="/avaliacao" component={Avaliacao}/> */}
      <Route path="/join" component={Join} isPrivate />
    </Switch>
  )
}
