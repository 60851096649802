import styled from 'styled-components';

export const BtnSend = styled.button`
    background:var(--site);
    font-size:14px;
    color:var(--branco);
    padding:15px 20px;
    border-radius:30px;
    border:none;
    text-transform:uppercase;
    font-weight:900;
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow:0 6px 15px rgba(0,0,0,0.3);
    bottom:20px;
    position:fixed;
    z-index:100;
    right:50px;
`;

export const BtnDefault = styled.button`
    background:var(--site);
    font-size:14px;
    color:var(--branco);
    padding:10px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
`;

export const BtnAdd = styled.div`
  width:44px;
  height:44px;
  background:var(--site);
  border-radius:50%;
  cursor:pointer;
  justify-content:center;
  align-items:center;
  display:flex;
`;

export const BtnClose = styled.div`
  height:30px;
  width:30px;
  cursor:pointer;
`;

export const BtnCancel = styled.button``;

export const FlagOnline = styled.span`
  background:var(--verde);
  font-size:10px;
  color: var(--branco);
  border-radius:50%;
`;

