import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100%;
  height:100%;
  background:var(--cinza-claro1);
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:flex-start;
`;

export const Margin = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:flex-start;
  padding:20px;
  height:100%;
`;

export const Container = styled.div`
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
`;

export const Top = styled.header`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    background:var(--branco);
    padding:10px;
    box-shadow:0px 0px 6px rgba(0,0,0,0.1);

    img{
      height:40px;
    }
    h1{
      font-size:20px;
      font-weight:800;
      color:var(--site);
      text-transform:uppercase;
    }
`;

export const BtnOpenMenu = styled.button`
  cursor:pointer;
  border:none;
  background:none;
`;

export const Avatar = styled.div`
  width:auto;
  height:auto;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;

  p{
    font-size:14px;
    font-weight:500;
    margin-right:10px;
  }
`;


export const NumAtendimento = styled.div`
  background:rgb(0, 182, 96,1);
  border: solid 2px #f3f3f3f3;
  color:#ffffff;
  padding:4px;
  font-size:25px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.3);
  margin-right: 10px;
  h3{
    font-size: 10px;
  }
`;
