import React from "react"
import {Dots} from "./styles"

const Loader = () =>{
      return(
        <Dots className={"dots"}>
          <div></div>
          <div></div>
          <div></div>
        </Dots>

      )


}

export default Loader;
