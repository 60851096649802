import styled from 'styled-components';


export const BtnMic = styled.button`
    background: transparent;
    /* width: 10%; */
    border-bottom: none;
    border-right: none;
    border-left: none;
    border-top:none;
    text-transform:uppercase;
    font-weight:700;
    padding: 0;
    font-size: 24px;
    color: #003752;
    :hover{
     color: #006ba0;

    }
    `;
export const BtnMicSend= styled.button`
background: rgb(22, 198, 12);
/* width: 10%; */
border: solid 1px transparent;
padding: 0;
font-size: 24px;
color: #ffffff;
border-radius: 50%;
width: 25px;
height: 25px;

text-align: center;
:hover{
 color: rgb(22, 198, 12);
 background:transparent;
 border: solid 1px rgb(22, 198, 12);
}
`;

export const BtnMicCancel= styled.button`
background: rgb(160, 51, 51);
/* width: 10%; */
border: solid 1px #ffffff;
padding: 0;
font-size: 24px;
color: #ffffff;
border-radius: 50%;
width: 25px;
height: 25px;

text-align: center;
:hover{
 color: rgb(160, 51, 51);
 background: transparent;
 border: solid 1px rgb(160, 51, 51);
}
`;




export const Dots = styled.div`
    display: flex;
    div{
      width: 3px;
    height: 3px;
    background: grey;
    border-radius: 50%;
    margin-left: 5px;

    animation: wave 0.5s ease-in-out infinite alternate;

    :nth-child(1){
      animation-delay: -0.4s;
    }

    :nth-child(2){
      animation-delay: -0.2s;
    }
    :nth-child(3){
      animation-delay: -0.1s;
    }

  }

    @keyframes wave{
      from{
        transform: translateY(-100%);
      }
      to{
        transform: translateY(100%);
      }

    }

`



