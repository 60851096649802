import React from "react";
import "./index.css"
import InforBarSRI from "../../components/InfoBarSRI/InfoBarSRI"
import { Link } from 'react-router-dom'
import queryString from "query-string"


const StartSRIChat = ({ location }) => {
    

    const { name, room } = queryString.parse(location.search)

  
    return (
        <div className="SRIcontainer">
            <InforBarSRI />
            <div style={{ padding: "10px", backgroundColor: "#F3F3F3", borderRadius: "25px", margin: "10px" }}>
                <p>Ao iniciar o atendimento, você entrará em contato direto via chat com um membro da equipe de <strong>suporte MACTUS</strong>.</p><br />
                <p style={{ fontSize: "12px" }}><b>Horario de atendimento:</b> 8h às 11:30 - 13h às 17:30</p>
            </div>
            <Link onClick={event => (!name || !room) ? event.preventDefault() : null} to={`/sri?name=${name}&room=${room}`}>
                <button className="button mt-20" type="submit">Iniciar Atendimento</button>
            </Link>

        </div>
    )

}

export default StartSRIChat;