import styled from 'styled-components';

export const Wrapper = styled.div`
  height:100%;
  background:var(--branco);
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  animation:fadeIn 0.3s ease-in-out;

`;

export const H1 = styled.h1`
  font-size:32px;
  color: var(--site);
  font-weight:700;
`;

export const Container = styled.div`
  width:100%;
  max-width:315px;
  text-align:center;

  form{
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-top:30px;

    input{
      border:2px solid var(--cinza);
      padding:15px;
      margin-bottom:10px;
      font-size:14px;
      font-weight:500;

      &::placeholder{
        color: var(--cinza);
      }
      &:focus{
        border-color:var(--cinza-escuro);
        color:var(--cinza-escuro);
      }
    }

    span{
      color:var(--vermelho);
      align-self:flex-start;
      margin-bottom:10px;
      font-weight:bold;
      animation:fadeIn 0.5s ease-in-out;
    }

    button{
      border-radius:6px;
      background:var(--site);
      padding:15px 10px;
      border:0px;
      color: var(--branco);
      font-weight:bold;
      margin:5px 0 0;
      text-transform:uppercase;
      font-size:14px;
      border:2px solid var(--site);
    }
    a{
      font-size:14px;
      color: var(--cinza-escuro);
      margin-top:20px;
      font-weight:700;
      transition:all 0.3s ease-in-out;


      &:hover{
        opacity:0.8;
      }
    }
  }
`;
