import React, { useState, useEffect, useLayoutEffect } from 'react';
import socketIOClient from 'socket.io-client';
import { FaTimes, FaCircle, FaTelegram, FaWhatsappSquare, FaWhatsapp } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs'
import { FaUserAlt, FaTelegramPlane } from 'react-icons/fa'
import { MdHeadsetMic } from 'react-icons/md'
import { IoIosArrowBack, IoIosArrowForward, IoLogoWhatsapp } from 'react-icons/io'
import alertSound from "../../audio/alertSound1.wav";

import { Howl, Howler } from "howler"
import iconeSRI from "../../imgs/sri.png"

import { ToastContainer, toast } from 'react-toastify';
import { foneMask } from '../../utils/mask';
// import apiProjectus from '../../services/apiProjectus';
import apiSga from '../../services/apiSga';
import If from '../../components/If';
import { Link } from 'react-router-dom'
import Aba from '../../components/Aba';

import {
  Abas,
  CloseAba,
  Line,
  BtnNovoAtendimento,
  BoxLigacoes,
  Colum,
  Scroll,
  BtnMinMax,
  BoxMinimizado
} from './styles';


Howler.autoUnlock = false;
//const ENDPOINT = 'http://192.168.1.166:3003/socket.io';
const ENDPOINT = "https://macatendimento.mactus.online"



let socket

export default function Dashboard() {
  // const [ativo, setAtivo] = useState(false);
  const [novaAba, setNovaAba] = useState([]);
  const [btnDesativado, setBtnDesativado] = useState(false);
  const [isMinimizado, setIsMinimizado] = useState(true);

  const [senhaAtendimento, setSenhaAtendimento] = useState('');
  const [ligacoes, setLigacoes] = useState([]);
  //{user : "inicial", room: "00"}
  const [userAntendimento, setUserAtendimento] = useState([]);
  const [roomAtendimento, setRoomAtendimento] = useState('');
  const [atendimentos, setAtendimentos] = useState([]);



  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on('ligacoes', (data) => {
  //     setLigacoes(data);
  //     // console.log(data);
  //   });
  // }, []);

  useEffect(() => {
    //const socket = socketIOClient(ENDPOINT);
    //console.log(socket)
  }, [novaAba]);


  useLayoutEffect(() => {
    socket = socketIOClient(ENDPOINT,
      {
        path: '/server-chat'
      });
    console.log(socket)
    async function joinListFunc() {
      await socket.on("joinList", (join) => {
        console.log(join)
        if(join!=null){
          setUserAtendimento(join.reverse())
        }

      })
    }
    joinListFunc()

    return () => {

      socket.off()
    }
  }, []);


  useEffect(() => {
    socket.on("joined", async ({ user, room, hora, sentBy, status, senha }) => {
      setUserAtendimento([{
        user: user,
        room: room, hora: hora, sentBy: sentBy, status: status,
         senha: senha
      }, ...userAntendimento])

      alertPlay(alertSound)

    })
    socket.on("novoAndamento", (novoAndamento) => {

      for (let i = 0; i < userAntendimento.length; i++) {

        if (userAntendimento[i].user == novoAndamento.user && userAntendimento[i].room == novoAndamento.room &&
          userAntendimento[i].hora == novoAndamento.hora) {
          console.log("Entrou IF NA")
          let userAtendimentoCopy = [...userAntendimento];
          userAtendimentoCopy[i].status = "andamento";
          userAtendimentoCopy[i].atendente = novoAndamento.atendente;
          setUserAtendimento(userAtendimentoCopy)

        }

      }
    })

    socket.on("novoPendente", (novoPendente) => {

      for (let i = 0; i < userAntendimento.length; i++) {

        if (userAntendimento[i].user == novoPendente.user && userAntendimento[i].room == novoPendente.room &&
          userAntendimento[i].hora == novoPendente.hora) {
          console.log("Entrou IF NP")
          var userAtendimentoCopy = [...userAntendimento];
          userAtendimentoCopy[i].status = "pendente";
          userAtendimentoCopy[i].atendente = "-----";
          setUserAtendimento(userAtendimentoCopy)

        }

      }
    })

    socket.on("finalizar", (finalizado) => {
      console.log(finalizado)
      console.log("Entrou Finalizado")
      for (let i = 0; i < userAntendimento.length; i++) {
        console.log("Entrou FOR Finalizado")
        console.log(finalizado.sentBy)
        if (userAntendimento[i].room == finalizado.room && userAntendimento[i].hora == finalizado.hora) {
          console.log("Entrou IF Finalizado")
          let userAtendimentoCopy = [...userAntendimento];
          userAtendimentoCopy[i].status = "finalizado";
          setUserAtendimento(userAtendimentoCopy)

        }
      }
    }
    )

    return () => {
      socket.off()
    }

  })

  function alertPlay(src) {

    console.log(src)
    const sound = new Howl({
      src
    })
    sound.play()
  }


  const addEmAtendimento = async (user, room, hora, atendente, senha) => {
    var varChamaSenha;
    await verificaTokenSga();
    const configChamaSenha = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
        'Content-Type': 'application/json'
      },
      //data:JSON.stringify({"sigla":`${senha.substring(0,1)}`,"numero":Number(senha.substring(1))}),
      maxBodyLength: -1
    };


    try {

      let i;
      if (novaAba.length) {
        i = novaAba.length;
      } else {
        i = 0;
      }

      if (i < 5) {
      console.log( senha.substring(0,1), Number(senha.substring(1)))
      const chamaSenhasEmAtendimento = await apiSga.get(
        `/atendimentos/chamarSenha?sigla=${senha.substring(0,1)}&numero=${Number(senha.substring(1))}`,
        configChamaSenha
      );
      console.log(chamaSenhasEmAtendimento)

      if (!chamaSenhasEmAtendimento) {
        await verificaTokenSga();
        chamaSenhasEmAtendimento = await apiSga.get(
          `/atendimentos/chamarSenha?sigla=${senha.substring(0,1)}&numero=${Number(senha.substring(1))}`,
          configChamaSenha
        );

      }

      if (chamaSenhasEmAtendimento.data.success === true) {
        // console.log(chamaSenha.data);
        setBtnDesativado(true);


          let senhas = 0;
          if (chamaSenhasEmAtendimento.data) {

            senhas = chamaSenhasEmAtendimento.data.data.senha
          }
          if (senhas) {
            console.log(senhas)
            iniciaAtendimento(senhas, i)
          }

      } else {
        toast.dark('A senha não foi chamada', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    }



    } catch (erro) {
      toast.dark('Não foi possível gerar a senha \n'+erro, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    socket.emit("passToAndamento", ({ user: user, room: room, hora: hora, atendente: atendente }))
    console.log(user, room, hora, atendente)
  }

  async function verificaTokenSga() {
    // login sga
    const bodyFormSga = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    bodyFormSga.append('grant_type', 'password');
    bodyFormSga.append(
      'client_id',
      '1_45blj1ewgaecswo8k08w04o4wgw0ks8sgk0gsgw88oos0kg04g'
    );
    bodyFormSga.append(
      'client_secret',
      '3ey7lcoaxhicocow8ss8s8o8swc4888swks0k4kc4wogo4wk0w'
    );
    bodyFormSga.append('username', localStorage.getItem('mac_login'));
    bodyFormSga.append('password', '123456');
    try {
      const resp = await apiSga.post('/token', bodyFormSga, config); // produção
      if (resp) {
        if (resp.data.error) {
          // console.log(resp.data)
          toast.dark(resp.data.error_description, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          localStorage.setItem('mac_sgatoken', resp.data.access_token);
          // await chamaSenha();
        }
      }
    } catch (err) {
      // showError(err)
      toast.dark(`Não foi possível fazer login no SGA`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  async function geraSenha() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
      },
    };
    try {
      const geraSenhas = await apiSga.post(
        '/distribui',
        {
          unidade: 1,
          servico: 1,
          prioridade: 1,
          cliente: {
            nome: '',
            documento: '',
          },
        },
        config
      );

      if (geraSenhas) {
        console.log(geraSenhas)
        if (geraSenhas.data.status === 'emitida') {
          return geraSenhas;
        }
        return false;
      }
    } catch (error) {
      // toast.dark('Não foi possível emitir uma senha', {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }

  async function chamaSenha() {
    let conectado = false;
    const gerada = await geraSenha();
    conectado = gerada;
    if (!gerada) {
      await verificaTokenSga();
      conectado = await geraSenha();
      // console.log(conectado);
    }
    // console.log(gerada);

    if (conectado) {

      var senhaGerada = conectado.data.senha;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
        },
      };
      try {
        const chamaSenhas = await apiSga.get(
          `/atendimentos/chamarSenha?sigla=${senhaGerada.sigla}&numero=${senhaGerada.numero}`,
          config
        );

        if (chamaSenhas.data.success === true) {
          // console.log(chamaSenha.data);
          return chamaSenhas.data;
        }
        toast.dark('A senha não foi chamada', {
          position: toast.POSITION.TOP_CENTER,
        });

        // console.log(chamaSenha.data.success);
      } catch (erro) {
        toast.dark('Não foi possível gerar a senha', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }

  async function iniciaAtendimento(senhas, i) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
      },
    };
    try {
      console.log(senhas)
      console.log("ENTROU IA")
      const iniciaAtendimento = await apiSga.post(
        '/atendimentos/iniciaAtendimento',
        senhas,
        config
      );
      // console.log(iniciaAtendimento.data.success);
      // console.log(iniciaAtendimento.data.id);
      const { success, data } = iniciaAtendimento.data;
      // console.log(iniciaAtendimento.data);
      const nsenha = data.senha.numero;
      const atende = {
        redirecionar: false,
        servicos: 1,
        resolucao: 'resolvido',
        observacao: 'obs',
        sigla: data.senha.sigla,
        numero: data.senha.numero,
      };

      setSenhaAtendimento(atende);
      setNovaAba([
        ...novaAba,
        { ativo: i ? 0 : 1, senha: atende, id: nsenha },
      ]);

      setTimeout(() => {
        setBtnDesativado(false);
      }, 500);

      if (success === true) {
        toast.success(`Atendimento iniciado Nº ${data.senha.numero}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.dark(`Não foi possível iniciar a senha de atendimento\n ${error}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  async function novoAtendimento() {
    setBtnDesativado(true);
    let i;
    if (novaAba.length) {
      i = novaAba.length;
    } else {
      i = 0;
    }
    if (i < 5) {
      const dados = await chamaSenha();

      let senhas = 0;
      if (dados) {
        senhas = dados.data.senha;
      }
      if (senhas) {
        iniciaAtendimento(senhas, i)
      }
    }
  }

  async function verificaTokenEncerraAtendimentoSga(senhas) {
    // login sga
    const bodyFormSga = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    bodyFormSga.append('grant_type', 'password');
    bodyFormSga.append(
      'client_id',
      '1_45blj1ewgaecswo8k08w04o4wgw0ks8sgk0gsgw88oos0kg04g'
    );
    bodyFormSga.append(
      'client_secret',
      '3ey7lcoaxhicocow8ss8s8o8swc4888swks0k4kc4wogo4wk0w'
    );
    bodyFormSga.append('username', localStorage.getItem('mac_login'));
    bodyFormSga.append('password', '123456');
    try {
      const resp = await apiSga.post('/token', bodyFormSga, config); // produção
      if (resp) {
        if (resp.data.error) {
          // console.log(resp.data)
          toast.dark(resp.data.error_description, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          localStorage.setItem('mac_sgatoken', resp.data.access_token);
        }
      }
    } catch (err) {
      // showError(err)
      toast.dark('Não foi possível fazer login no SGA', {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    const configToken = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
      },
    };
    try {
      const encerra = await apiSga.post(
        '/atendimentos/encerraAtendimento',
        senhas,
        configToken
      );

      if (encerra.data.success == true) {
        const { numero } = senhas;
        toast.success(`Atendimento finalizado Nº ${numero}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.dark(
        'Não foi possível finalizar o atendimento,verifique se você tem algum atendimento em aberto',
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }

  async function encerraAtendimento(senhas) {
    try {
      console.log(senhas)
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
        },
      };
      const encerra = await apiSga.post(
        '/atendimentos/encerraAtendimento',
        senhas,
        config
      );

      if (encerra.data.success == true) {
        const { numero } = senhas;
        toast.success(`Atendimento finalizado Nº ${numero}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      await verificaTokenEncerraAtendimentoSga(senhas);

      // toast.dark('Não foi possível finalizar o atendimento,verifique se você tem algum atendimento em aberto', {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }

  function criaNovaAba() {
    let i;
    if (novaAba.length) {
      i = novaAba.length;
    } else {
      i = 0;
    }
    if (i < 5) {
      setNovaAba([
        ...novaAba,
        { ativo: i ? 0 : 1, senha: senhaAtendimento, id: (i += 1) },
      ]);
    }

    // console.log(novaAba);
  }

  function mudaAba(key) {
    const ab = [];
    ab.push(novaAba);
    const atual = novaAba.map((item) => {
      if (item.id === key) {
        item.ativo = 1;
      } else {
        item.ativo = 0;
      }
      return item;
    });
    setNovaAba(atual);
    // console.log(novaAba);
  }

  function fechaAba(key) {
    const retorno = window.confirm(
      'Tem certeza que deseja fechar esse atendimento?'
    );
    if (retorno) {
      const i = novaAba.length;
      const fecha = novaAba.filter((aba) => aba.id != key);
      const aba = novaAba.filter((aba) => aba.id == key);

      encerraAtendimento(aba[0].senha);
      setNovaAba(fecha);
    }
  }

  function fechaAbaEncerraAtendimento(key) {
    const i = novaAba.length;
    const fecha = novaAba.filter((aba) => aba.id !== key);
    // const aba = novaAba.filter(aba => aba.id == key);
    console.log(fecha);
    setNovaAba(fecha);
  }

  function handleMinimizado() {
    if (isMinimizado === true) {
      setIsMinimizado(false)

    } else {
      setIsMinimizado(true)
    }

  }

  function blockOpenChat(e){
    e.preventDefault();
    toast.dark(`Muitos atendimentos abertos simultaneamente(${novaAba.length})`, {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  const currentUser = `▪ ${localStorage.getItem('mac_usuario').split(" ")[0]}`

  return (
    <>
      <Colum>
        <ToastContainer
          position="top-left"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Line>
          <BtnNovoAtendimento
            disabled={btnDesativado}
            onClick={novoAtendimento}
          >
            Novo Atendimento
          </BtnNovoAtendimento>
        </Line>
        <Line>
          {novaAba.map((aba) => {
            return (
              <Abas ativo={aba.ativo} key={aba.id}>
                <h3 onClick={() => mudaAba(aba.id)}>Atendimento {aba.id}</h3>
                <CloseAba title="Fechar">
                  <FaTimes
                    onClick={() => fechaAba(aba.id)}
                    color="#666"
                    size={16}
                  />
                </CloseAba>
              </Abas>
            );
          })}
        </Line>
        <>
          {novaAba.map((aba) => {
            if (aba) {
              return (
                <Aba
                  ativo={aba.ativo}
                  abaId={aba.id}
                  fechaAbaEncerra={fechaAbaEncerraAtendimento}
                  senha={aba.senha}
                  key={aba.id}
                />
              );
            }
            return null;
          })}
        </>
      </Colum>
      {/* <Colum>
        <BoxLigacoes>
          <h1>Ligações em espera</h1>
          <If teste={ligacoes}>
            <Scroll>
              <ul>
                {ligacoes.map((home) => (
                  <li title="Atender" key={home.id} onClick={novoAtendimento}>
                    <FiPhoneCall size={14} style={{ marginRight: '10px' }} />
                    {foneMask(home.telefone)}
                  </li>
                ))}
              </ul>
            </Scroll>
          </If>
        </BoxLigacoes>
      </Colum> */}

      <Colum style={{ position: "relative", float: "left" }}>
        <div id={"listDivisor"} style={{ display: "flex" }} >
          <BtnMinMax estado={isMinimizado} onClick={event => handleMinimizado()}>
            {isMinimizado === true ? <IoIosArrowForward className={"btnMinimize"} /> : <IoIosArrowBack className={"btnMinimize"} />}
          </BtnMinMax>
          {isMinimizado === true ?
            <BoxLigacoes>
              <div id={"titleList"}>

                <h2> Total diário: <br/>
                (via Chat)</h2>
                <div id={"numPendentes"}>{userAntendimento.filter(x => x.status === 'pendente').map(x => x.status).length}</div>
                <div id={"numAndamentos"}>{userAntendimento.filter(x => x.status === 'andamento').map(x => x.status).length}</div>
                <div id={"numFinalizados"}>{userAntendimento.filter(x => x.status === 'finalizado').map(x => x.status).length}</div>
              </div>

              <Scroll>

                <ul >
                  {userAntendimento.map((atendimento, i) => (
                    <Link onClick={event => (!atendimento.user || !atendimento.room || novaAba.length>=5) ? blockOpenChat(event) : addEmAtendimento(atendimento.user, atendimento.room, atendimento.hora, currentUser,
                      atendimento.senha
                      )}
                      to={`/chat?name=${currentUser}&room=${atendimento.room}&hora=${atendimento.hora}&cliente=${atendimento.user}`}
                      target="_blank">
                      <li className={atendimento.status == "pendente" ? "itemListPendente" : atendimento.status == "andamento" ? "itemListAndamento" : "itemListFinalizado"}>

                        <div id={"statusAtendimento"}>
                          <div>
                            <p style={{ fontSize: "12px", fontWeight: "700" }}>
                              {atendimento.status == "pendente" ?
                                <><FaCircle size={10} style={{ color: "#880000" }} /> {atendimento.senha}
                                </>
                                : atendimento.status == "andamento" ? <> <FaCircle size={10} style={{ color: "#0000ff" }} /> {atendimento.senha}
                                   </>
                                  : <> <FaCircle size={15} style={{ color: "#008000" }} /> {atendimento.senha}
                                  </>}
                            </p>
                            <p>
                              <MdHeadsetMic size={12} />: {atendimento.atendente}

                            </p>
                          </div>

                          <div>

                            {atendimento.sentBy == "SRI" ? <img src={iconeSRI} width={"25px"} height={"25px"} /> : atendimento.sentBy == "telegram" ? <FaTelegram size={25} color={"#0088cc"} /> : <IoLogoWhatsapp size={25} color={"#25D366"} />}
                            <p>
                              <strong>{atendimento.hora}</strong>
                            </p>
                          </div>
                        </div>

                        <div id={"dadosAtendimento"}>
                          <p>
                            <b><FaUserAlt size={12} />: {atendimento.user}</b><br /><div style={{ fontSize: "10px" }}></div>
                            <div style={{ fontFamily: "'Roboto',sans-serif" }}><BsChatDotsFill size={12} />: {atendimento.room}</div>
                          </p>
                        </div>


                      </li>
                    </Link>

                  ))}

                </ul>
              </Scroll>

            </BoxLigacoes>
            :
            <BoxMinimizado>
              <Scroll>

                <ul >
                  {userAntendimento.map((atendimento, i) => (

                    <Link onClick={event => (!atendimento.user || !atendimento.room || novaAba.length>=5) ? event.preventDefault() : addEmAtendimento(atendimento.user, atendimento.room, atendimento.hora, currentUser)}
                      to={`/chat?name=${currentUser}&room=${atendimento.room}&hora=${atendimento.hora}&cliente=${atendimento.user}`}
                      target="_blank">
                      <li className={atendimento.status == "pendente" ? "itemListPendente" : atendimento.status == "andamento" ? "itemListAndamento" : "itemListFinalizado"}>
                        {atendimento.sentBy == "SRI" ? <img src={iconeSRI} width={"35px"} height={"35px"} /> : atendimento.sentBy == "telegram" ? <FaTelegram size={33} color={"#0088cc"} /> : <IoLogoWhatsapp size={33} color={"#25D366"} />}
                      </li>
                    </Link>

                  ))}

                </ul>
              </Scroll>
            </BoxMinimizado>
          }

        </div>
      </Colum>


    </>
  );
}
