import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

:root{
--site-gradiente:linear-gradient(45deg,#0cace4, #003752);
--site:#003752;
--amarelo:#ffff00;
--verde:#00B660;
--branco:#ffffff;
--cinza-claro1:#f3f5f8;
--cinza-claro:#cccccc;
--cinza:#999999;
--cinza-medio:#666666;
--cinza-escuro:#333333;
--preto:#000000;
--vermelho:#FA5858;

}
  * {
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
  }

  html,body,#root{
    min-height:100%;
    height:100%;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
  }

  body{
    background:var(--branco);
    -webkit-font-smoothing:antialiased !important;
  }

  body,input,textarea,button{
    font-size:14px;
    font-family:'Montserrat', sans-serif;
  }

  input,textarea{
    border-radius:6px;
    padding:10px;
    border:1px solid var(--cinza-claro);
    width:100%;
    height:auto;
    margin-right:10px;
    margin-bottom:10px;
  }

  input[type=checkbox],input[type=radio]{
    border-radius:6px;
    padding:15px;
    border:1px solid var(--cinza-claro);
    height:25px;
    margin-bottom:0px;
    margin-right:10px;
    width: 25px;
    float: left;
  }

  input[type=file]{
    border-radius:6px;
    padding:20px 10px;
    border:1px solid var(--cinza);
    background:var(--cinza-claro);
    height:auto;
    margin:5px 0px 15px 0px;
    cursor:pointer;
  }

  span{
    color:var(--vermelho);
    display:block;
    margin-top:-10px;
    margin-bottom:10px;
  }

  section{
    max-width:960px;
    margin:0px auto;
    display:flex;
    flex-direction:column;
  }

  a{
    text-decoration:none;
  }

  button{
    cursor:pointer;
  }



  /*animations*/
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; display:flex; }
    }
    @-moz-keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1; display:flex; }
    }
    @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; display:flex; }
    }
    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; display:flex; }
    }

    @-webkit-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; display:none; }
    }
    @-moz-keyframes fadeOut {
    0% { opacity: 1;}
    100% { opacity: 0; display:none; }
    }
    @-o-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; display:none; }
    }
    @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; display:none; }
    }

    @-webkit-keyframes slideDown {
    0% { transform:translateY(-20%); }
    100% { transform:translateY(0); }
    }
    @-moz-keyframes slideDown {
      0% { transform:translateY(-20%); }
    100% { transform:translateY(0); }
    }
    @-o-keyframes slideDown {
      0% { transform:translateY(-20%); }
    100% { transform:translateY(0); }
    }
    @keyframes slideDown {
      0% { transform:translateY(-20%); }
    100% { transform:translateY(0); }
    }

    @-webkit-keyframes slideUp {
    0% { transform:translateY(0); }
    100% { transform:translateY(20%); }
    }
    @-moz-keyframes slideUp {
      0% { transform:translateY(0); }
    100% { transform:translateY(20%); }
    }
    @-o-keyframes slideUp {
      0% { transform:translateY(0); }
    100% { transform:translateY(20%); }
    }
    @keyframes slideUp {
      0% { transform:translateY(0); }
    100% { transform:translateY(20%); }
    }
`;
