import React, { useState, useEffect } from "react"
import  {Link, Redirect } from 'react-router-dom'
import queryString from "query-string"
import io from "socket.io-client"


import "./chat.css"
import InforBarSRI from "../../components/InfoBarSRI/InfoBarSRI"
import InputSRI from "../../components/InputSRI/InputSRI"
import Messages from "../../components/Messages/Messages"

import Compressor from 'compressorjs';

let socket;
const suporte = false
const sentBy = 'SRI'

const SRIChat = ({ location }) => {
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([{user: "admin", text:`Em instantes um membro do time de suporte Mactus entrará em contato. Por favor, informe o motivo do contato.`}]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileBase64String, setFileBase64String] = useState("");
    const [finalizado,setFinalizado] = useState(false);
    const [finalizadoStr,setFinalizadostr] = useState("")
    const [audioURL, setAudioURL] = useState(",,,,,");
    const [stateRecord, setStateRecord] = useState("false");
    const [recorder, setRecorder] = useState(null);
    const [atendente, setAtendente] = useState('');
    const [rate, setRate] = useState('');
    const [rateText, setRateText] = useState('');
    const [question, setQuestion] = useState('');
    const [colorRate, setColorRate] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [isAvaliado, setIsAvaliado] = useState(false);


    const ENDPOINT = "https://macatendimento.mactus.online"
    var trimmedRoom = `${room.trim().toLowerCase()}`



    useEffect(() => {

        async function fetchData() {
        const { name, room } = queryString.parse(location.search)

        socket = io(ENDPOINT, {
            path: '/server-chat'
          })

        setName(`${name}`);
        setRoom(`${room}`);
        console.log(name, room)
        await socket.emit("join", { name, room, suporte, sentBy }, () => {
            console.log({ name, room, suporte, sentBy })
        })
          }
        fetchData();

       document.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });


        return () => {
            socket.off();
        }
    }, [ENDPOINT]);


    useEffect(() => {
        socket.on('message', (message) => {
            setMessages([...messages, message])

        })

        socket.on('previousMessage', (message) => {

            var auxMessage = []
            for (let i = 0; i < message.length; i++) {
                auxMessage.push({ user: message[i].nome, text: message[i].mensagem,  hora: message[i].hora, data: message[i].data  })
            }
            setMessages([...messages, ...auxMessage])

        })

        return () => {
            socket.off()
        }

    }, [messages])


    useEffect(() => {
        console.log(selectedFile)
        encodeFileBase64(selectedFile[0])

    }, [selectedFile])

    useEffect(() => {
        var imgBase64
        if (fileBase64String != "") {
            //console.log("fileString: "+ fileBase64String)
            imgBase64 = fileBase64String
            console.log("Tamanho Base64String" + imgBase64.length)
            try {
                socket.emit('sendMessage', {
                    lastMessage: imgBase64,
                    chatID: room,
                    nameUser: name
                }, () => {
                    console.log("Arquivo enviado")
                    setFileBase64String('')
                })
            } catch (error) {
                console.log(error)
            }
        }


    }, [fileBase64String])

    useEffect(() => {
        socket.on("finalizarSRI", (finalizado) => {
            setAtendente(finalizado.atendente)
            console.log("Entrou FinalizadoSRI")
            setFinalizado(true)
            //setFinalizadostr(`${finalizado}`)
        })
    })

    useEffect(() => {

      // Lazily obtain recorder first time we're recording.
      if (recorder === null) {
        if (stateRecord==="true") {

          requestRecorder().then(setRecorder, console.error);
        }
        return;
      }

      // Manage recorder state.
      if (stateRecord==="true" && recorder) {
        if(recorder.state=="inactive"){
          recorder.start();
          console.log(recorder.state)
        }
      } else if(recorder){
        if(recorder.state=="recording"){
          recorder.stop();
        }
      }

      // Obtain the audio when ready.
      const handleData = e => {

      if(stateRecord==="cancel"){
        console.log("Audio cancelado")
      }else{
        console.log(recorder.state)
        var reader = new FileReader();
        reader.readAsDataURL(e.data);
        reader.onloadend = function() {
        var base64data = reader.result;

        socket.emit('sendMessage', {
          lastMessage: base64data,
          chatID: room,
          nameUser: name
      }, () =>  console.log(base64data))
      }
      }

    //  setAudioURL(URL.createObjectURL(e.data));
      };

        if(recorder){
          recorder.addEventListener("dataavailable", handleData);
        }


        // setRecorder(null)

      return () => {
        if(recorder){
            recorder.removeEventListener("dataavailable", handleData)
          }}

    }, [recorder, stateRecord]);


    const disconnectChat = (event) => {
        socket.emit("exit", ({ room: room.trim().toLowerCase(), name: name.trim().toLowerCase(), suporte: suporte }))
        socket.disconnect().then(console.log("desconectou!")).catch((err) => { console.log("Ocorreu um erro ao desconectar: " + err) })
    }

    const sendMessage = (event) => {

        event.preventDefault();
        if (message) {

            socket.emit('sendMessage', {
                lastMessage: message,
                chatID: room,
                nameUser: name
            }, () => setMessage(''))
        }
    }


    const onFileChange = async (e) => {
        console.log(e.target.files[0])
        console.log(e.target.files)
        setSelectedFile(e.target.files);
        //console.log(file)


    }


    const encodeFileBase64 = (file) => {
        if (file) {
            new Compressor(file, {
                maxWidth: 300,
                quality: 0.6,
                success(result) {

                    console.log(result)
                    var reader = new FileReader();

                    console.log("If File")
                    reader.readAsDataURL(result)
                    reader.onload = () => {
                        var Base64 = reader.result
                        setFileBase64String(Base64)
                    };
                    reader.onerror = function (error) {
                        console.log("Error Encode Base64: ")
                        console.log(error)
                    }



                },
                error(err) {
                    console.log(err.message);
                },
            });

        }

    }

    const startRecording = (e) => {
      console.log("START RECORD")
      setStateRecord("true");

};

const stopRecording = (e) => {
  console.log("STOP RECORD")
  setStateRecord("false");
//     var reader = new FileReader();
//     reader.readAsDataURL(audioURL);
//     reader.onloadend = function() {
//     var base64data = reader.result;
//     console.log(base64data);
//  }
};

const cancelRecording = (e) => {
setStateRecord("cancel")
};

async function requestRecorder() {
  try{
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
  }
  catch(err){
     window.alert("Não foi possível identificar o microfone")
     console.log("Não foi possível identificar o microfone")
     setStateRecord(false)

  }
}


const handleRateStars = (e)=>{
  switch (e) {
    case "Ótimo":
    setRate("5")
    setRateText("Ótimo")
    setColorRate("#D4EDDA")
  break;
   case "Bom":
  setRate("4")
  setRateText("Bom")
  setColorRate("#fcdbfc")
  break;
   case "Satisfatório":
   setRate("3")
   setRateText("Satisfatório")
   setColorRate("#cce5ff")
   break;
  case "Ruim":
    setRate("2")
    setRateText("Ruim")
    setColorRate("#FFF3CD")
  break;
  case "Péssimo":
    setRate("1")
    setRateText("Péssimo")
    setColorRate("#F8D7DA")
    break;
  default:
      break;
  }
}


const handleSubmit = (e) =>{
  e.preventDefault();

  if(rate==="" || question ===""){
    setAlertMessage("*Existem campos em branco*")
  }else{
    setAlertMessage("")
    try{
      socket.emit("avaliacao", { name: name, room: room ,atendente: atendente.substring(2) , question: question , rateStar: rate, sentBy: sentBy }, ()=>{
        console.log( name, room, atendente)
        console.log( question +" - "+ rate   )
      setIsAvaliado(true)
      })

    }catch(err){
      console.log("Erro ao enviar avaliação: "+err)
      setAlertMessage("*Erro ao enviar avaliação*")
    }


  }

}



    return (

        <div className="SRIcontainer">
            <InforBarSRI room={room} />
            {finalizado===false?
            <>
            <Messages messages={messages} name={name} />
            <InputSRI message={message} setMessage={setMessage} sendMessage={sendMessage} sendFile={onFileChange}
           stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}   />
           </>
            :isAvaliado===false?
            // <Redirect to={{
            //   pathname: "/avaliacao",
            //   search: `?name=${name}&room=${room}&atendente=${atendente}`
            // }} />
              <>
            <Messages messages={messages} name={name} />

          <div className={"containerRating"}>
            <form onSubmit={(event)=> handleSubmit(event)}>
              <p><i>Chat finalizado!</i></p><br />
              <h4><b>Seu problema foi resolvido e/ou sua dúvida foi sanada?</b></h4>
              <div className={"question1"}>
              <input style={{height: "15px"}}type="radio" id="question1Sim" name="question1" value="sim" onClick={(event)=> setQuestion("Sim")}/>
              <label for="question1Sim">Sim</label><br/>
              <input style={{height: "15px"}} type="radio" id="question1Nao" name="question1" value="nao" onClick={(event)=> setQuestion("Não")}/>
              <label for="question1Nao">Não</label><br/>
              <input style={{height: "15px"}} type="radio" id="question1Parcial" name="question1" value="parcial" onClick={(event)=> setQuestion("Parcial")}/>
              <label for="question1Parcial">Parcialmente</label>
              </div>

              <div className={"titleRating"} style={{backgroundColor: colorRate}}>
              <h4>Avalie nosso atendimento: </h4>
              {rateText}
              </div>
              <div className={"rating"}>
                <input type={"radio"} name="star" id="star1" onClick={(event)=> handleRateStars("Ótimo") }/><label  htmlFor="star1"></label>
                <input type={"radio"} name="star" id="star2" onClick={(event)=> handleRateStars("Bom")}/><label htmlFor="star2"></label>
                <input type={"radio"} name="star" id="star3" onClick={(event)=> handleRateStars("Satisfatório")}/><label htmlFor="star3"></label>
                <input type={"radio"} name="star" id="star4" onClick={(event)=> handleRateStars("Ruim")}/><label htmlFor="star4"></label>
                <input type={"radio"} name="star" id="star5" onClick={(event)=> handleRateStars("Péssimo")}/><label htmlFor="star5"></label>
              </div>
              <button className={"BtnSendRating"} type={"submit"}>
                Enviar
              </button>
              <p style={{color: "red", margin: "5px"}}>{alertMessage}</p>
            </form>
           </div>

           {/* <InputSRI message={message} setMessage={setMessage} sendMessage={sendMessage} sendFile={onFileChange}
           stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}   /> */}
              </>
              :
              <Redirect to={{
                  pathname: "/start",
                  search: `?name=${name}&room=${room}`
                }} />
            }

        </div>


    )
}

export default SRIChat;
