import React, { useState, useEffect,useLayoutEffect } from "react"
import {BtnMic, BtnMicSend,BtnMicCancel} from "./styles"
import Loader from "./loader"
import { TiMicrophone } from 'react-icons/ti'
import {IoIosCloseCircle, IoIosCheckmarkCircle,IoIosCheckmark,IoIosClose}from 'react-icons/io'
const microfone = ({stateRecord,stopRecording, startRecording, cancelRecording})=>{
    return(
    <>
    {stateRecord==="true"?
    (
      <div style={{display:"flex", padding: "8px"}} >
        <BtnMicCancel onClick={(event)=> cancelRecording(event)}>
        <IoIosClose/>
        </BtnMicCancel>
        <div style={{marginTop:"10px", marginRight:"5px"}}><Loader/></div>
        <BtnMicSend id="btnMic" name="btnMic" onClick={(event)=> stopRecording(event)}>
        <IoIosCheckmark/>
        </BtnMicSend>
        <b style={{fontSize:"15px", marginTop: "2px", color:"grey"}}>Gravando </b>
      </div>

    )
      :
      <BtnMic id="btnMic" name="btnMic" onClick={(event)=> startRecording(event)}>

       <TiMicrophone/>
      </BtnMic>
    }
    </>
    )

}

export default microfone
