import styled from 'styled-components';

export const Card = styled.div`
  max-width:100%;
  background:var(--branco);
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:flex-start;
  padding:20px;
  border-radius:0px 10px 10px 10px;
  border:1px solid var(--cinza-claro);
  height:auto;
  animation: fadeIn 0.3s ease-in-out;
  position:absolute;
  transform:translateY(112px);
  z-index:${props => props.ativo ? "1" : "0"};
`;

export const Line = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:center;
`;

export const Abas = styled.div`
  padding:10px;
  width:auto;
  height:auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background:var(${props => props.ativo ? "--branco" : "--cinza-claro"});
  color:var(${props => props.ativo ? "--cinza-escuro" : "--branco"});
  border-radius:10px 10px 0px 0px;
  margin:0px 10px 0px 0px;
  border-top:1px solid var(--cinza-claro);
  border-left:1px solid var(--cinza-claro);
  border-right:1px solid var(--cinza-claro);
  cursor:pointer;
  transition:all 0.3s ease-in-out;
`;

export const CloseAba = styled.div`
  margin-left:10px;
`;

export const BtnDefault = styled.button`
    background:var(--verde);
    font-size:13px;
    color:var(--branco);
    padding:15px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
`;

export const BtnSubmit = styled.button`
    background:var(--verde);
    font-size:13px;
    color:var(--branco);
    padding:15px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
    margin-top:10px;
`;

export const BtnAzul = styled.button`
    background:var(--site);
    font-size:13px;
    color:var(--branco);
    padding:15px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
    margin-top:10px;
    margin-right:20px;
`;

export const BtnSelect = styled.button`
    border-radius:6px;
    background:var(--branco);
    padding:10px 15px;
    border:1px solid var(--cinza-claro);
    flex:1;
    justify-content:space-between;
    flex-direction:row;
    align-items:center;
    margin-right:10px;
    height:auto;
    margin-bottom:10px;
`;

export const BtnAtendimentos = styled.div`
    border-radius:6px;
    background:var(--branco);
    padding:5px 10px;
    border:1px solid var(--cinza-claro);
    flex:1;
    justify-content:space-between;
    flex-direction:row;
    align-items:center;
    width:auto;
    margin-right:10px;
    margin-bottom:10px;
`;

export const BtnNovoAtendimento = styled.button`
    background:var(--verde);
    font-size: 12px;
    color: var(--branco);
    padding: 20px 10px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
    margin-bottom:10px;
`;

export const Label = styled.label`
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:flex-start;
  align-items:center;
  display:flex;
  margin-bottom:5px;
  margin-left:10px;
  cursor:pointer;
`;

export const BoxImagem = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  border-radius:6px;
  background:var(--cinza-claro);
  padding:10px;
  margin:0px 5px 10px;
  cursor:pointer;
`;

export const ReactAutocomplete = styled.div`
  display:flex; 
`;

export const BoxSelect = styled.div`
  padding:5px 0px;
  cursor:pointer;
`;

export const InputAnexo = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  font-size:16px;
  font-weight:bold;
`;

