import React from 'react';
import logo from '../../imgs/logo.png';
import { Container, Logo } from './styles';

function Menu({ visible }) {
  return (
    <Container visible={visible}>
      <Logo>
        <img alt="Mactus Soluções em T.I." src={logo} height={60} />
        <h1>Mac Atendimento</h1>
      </Logo>
    </Container>
  );
}

export default Menu;
