import React from 'react';

function Externas({ children }) {
  return (
    <>
      {children}
    </>
  )
}

export default Externas;
