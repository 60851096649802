import React, { useState, useEffect } from 'react';
import history from '../../../services/history';
import apiMacGestao from "../../../services/apiMacGestao"
import { Wrapper, Top, BtnOpenMenu, Container, Margin, Avatar,NumAtendimento } from './styles';
import Menu from '../../../components/Menu';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BtnDefault } from '../../../styles/btns';
import { getDerivedStateFromProps } from 'react-currency-formatter';

function Internas({ children }) {
  const [visible, setVisible] = useState(false);
  const [usuario, setUsuario] = useState();
  const [numAtendimentos, setNumAtendimentos] = useState();
  useEffect(() => {
    setUsuario(localStorage.getItem('mac_usuario'));


  }, []);

  useEffect(
    ()=>{

        var data = new Date()
        var dia = data.getDate();
        var mes = data.getMonth()+1;
        var ano = data.getFullYear();

      console.log(`${ano}/${`${mes}`.length> 1 ? mes : "0" + mes}/${`${dia}`.length > 1 ? dia : "0" + dia}`)
      getDadosAtendimento(`${ano}-${`${mes}`.length> 1 ? mes : "0" + mes}-${`${dia}`.length > 1 ? dia : "0" + dia}`)

    }
  )

  async function getDadosAtendimento(dia){
    let atendentes = await apiMacGestao.get(`/atendimentos/listaAtendentes/${dia}/${dia}`)

    let atendimentos = await apiMacGestao.get(`/atendimentos/listaQtdServico/Atendimento/${dia}/${dia}`)


    let totalAtendentes= []
    if(atendentes.data.length == atendimentos.data.length){

        for (let i = 0; i < atendentes.data.length; i++) {
          totalAtendentes.push({nome: atendentes.data[i].nome, total:atendimentos.data[i].total })

        }
        console.log(totalAtendentes)
    }

    if (totalAtendentes==""){
      console.log("0000000000000000")
        setNumAtendimentos("0")
    }else{
      console.log(totalAtendentes)
      setNumAtendimentos(totalAtendentes.filter(x => x.nome.toLowerCase() === `${localStorage.getItem('mac_usuario').split(" ")[0]}`.toLowerCase())[0].total)
    }


  }


  function openMenu() {
    setVisible(!visible);
    //console.log(visible)
  }

  function logout() {
    localStorage.setItem('mac_login', "");
    localStorage.setItem('mac_usuario', "");
    localStorage.setItem('mac_logged', "");
    localStorage.setItem('mac_token', "");
    localStorage.setItem('mac_sgatoken', "");
    localStorage.setItem('mac_idusuario', "");
    history.push('/');
  }

  return (
    <Wrapper>
      <Menu visible={visible} />
      <Container>
        <Top>
          <BtnOpenMenu><GiHamburgerMenu size={32} color="#333" onClick={openMenu} /></BtnOpenMenu>



          <Avatar>
          {/* <NumAtendimento >
            <h3>Nº ATENDIMENTOS:</h3>
            {numAtendimentos}
          </NumAtendimento> */}
            <p>{usuario}</p>
            <BtnDefault onClick={logout}>Sair</BtnDefault>
          </Avatar>
        </Top>
        <Margin>
          {children}
        </Margin>
      </Container>
    </Wrapper>
  )
}

export default Internas;
