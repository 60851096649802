import React, { useState, useEffect, memo } from 'react';
import ReactAutocomplete from 'react-autocomplete';
import { FaTimes, FaWhatsapp, FaLandmark } from 'react-icons/fa';
// import Modal from '../../components/Modal';
import { Form, Input, Check } from '@rocketseat/unform';

import { BsChatDots } from 'react-icons/bs';
import { FiSmartphone, FiPhone, FiMail } from 'react-icons/fi';
import { RiSkypeLine, RiWindowLine } from 'react-icons/ri';

import moment from 'moment';
import 'moment/locale/pt-br';

import { ToastContainer, toast } from 'react-toastify';
import apiProjectus from '../../services/apiProjectus';
import If from '../If';
import apiSga from '../../services/apiSga';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  Line,
  BtnSubmit,
  BtnAtendimentos,
  Label,
  BoxImagem,
  BtnAzul,
  InputAnexo,
} from './styles';

function Aba({ ativo, senha, abaId, fechaAbaEncerra }) {
  // const { ativo, senha, abaId, fechaAbaEncerra } = props;
  const [styleMenu, setStyleMenu] = useState({
    borderRadius: '6px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '5px',
    fontSize: '90%',
    position: 'absolute',
    overflow: 'auto',
    maxHeight: '50%',
    top: 'auto',
    marginTop:'40px',
    left:'auto'
  });

  const horaAtual = new Date();
  const data = moment().format('DD/MM/YYYY');
  const hora = `${new Date().getHours()}:${new Date().getMinutes()}`;

  const [btnFinalizar, setBtnFinalizar] = useState(false);
  const [tipo, setTipo] = useState(false);
  const [modalVisivel, setModalVisivel] = useState(false);
  const [resolvido, setResolvido] = useState(true);
  const [copiaPendencia, setCopiaPendencia] = useState(false);

  const [dadosListagens, setDadosListagens] = useState([]);

  const [dataInicio, setDataInicio] = useState(data);
  const [horaInicio, setHoraInicio] = useState(hora);

  const [cliente, setCliente] = useState(null);
  const [dadosCliente, setDadosCliente] = useState([{ id: '', value: '' }]);

  const [contato, setContato] = useState(null);
  const [categoriaProblema, setCategoriaProblema] = useState(null);
  const [projeto, setProjeto] = useState(null);

  const [clienteId, setClienteId] = useState(null);
  const [contatoId, setContatoId] = useState(null);
  const [tipoSuporteId, setTipoSuporteId] = useState(null);
  const [tipoAtendimentoId, setTipoAtendimentoId] = useState(1);
  const [categoriaProblemaId, setCategoriaProblemaId] = useState(null);
  const [projetoId, setProjetoId] = useState(null);

  const [problema, setProblema] = useState('');
  const [solucao, setSolucao] = useState('');
  const [observacao, setObservacao] = useState('');
  const [caminhoAnexo, setCaminhoAnexo] = useState([]);

  const [pendenciaEspecificaCliente, setPendenciaEspecificaCliente] = useState(
    false
  );
  const [desenvolvimento, setDesenvolvimento] = useState(false);
  const [finalizar, setFinalizar] = useState(false);

  const [dadosServentias, setDadosServentias] = useState([]);
  const [dadosContatoCliente, setDadosContatoCliente] = useState([]);
  const [dadosProjetosCliente, setDadosProjetosCliente] = useState([]);
  const [dadosCategoriaProblema, setDadosCategoriaProblema] = useState([]);

  async function listaDadosServentias() {
    try {
      const resp = await apiProjectus.get(`/ListaServentias.htm`);
      setDadosServentias(resp.data);
    } catch (err) {
      toast.dark('Não foi possível carregar os dados, verifique sua conexão', {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(err);
    }
  }

  async function listaDadosContatoCliente() {
    if (clienteId) {
      try {
        const resp = await apiProjectus.get(
          `/ListaContatoCliente.htm?id=${clienteId}`
        );
        if (resp.data) {
          setDadosContatoCliente(resp.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function listaDadosProjetosCliente() {
    try {
      const resp = await apiProjectus.get(
        `/ListaProjetosCliente.htm?id=${clienteId}`
      );
      if (resp.data) {
        setDadosProjetosCliente(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function listaDadosCategoriaProblema() {
    try {
      const resp = await apiProjectus.get(`/ListaCategoriaProblema.htm`);
      if (resp.data) {
        setDadosCategoriaProblema(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    let montado = false;
    if (!montado) {
      listaDadosServentias();
      listaDadosCategoriaProblema();
    }
    return () => (montado = true);
  }, []);

  useEffect(() => {
    let montado = false;
    if (!montado) {
      listaDadosContatoCliente();
      listaDadosProjetosCliente();
      listaDadosCategoriaProblema();
    }
    return () => (montado = true);
  }, [clienteId]);

  async function gravaAnexos(id) {
    const ids = id;
    let i;
    for (let i = 0; i < caminhoAnexo.length; i++) {
      const bodyFormData = new FormData();
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const token = localStorage.getItem('mac_token');
      const usuarioId = localStorage.getItem('mac_idusuario');

      bodyFormData.append('caminho', caminhoAnexo[i].path);
      bodyFormData.append('label', caminhoAnexo[i].label);
      bodyFormData.append('pendencia_id', ids);
      // console.log(caminhoAnexo[i].caminho);

      // const conteudo = caminhoAnexo.map(item => {
      //   return (
      //     item.caminho,
      //     item.label,
      //     ids
      //   )
      // });

      // console.log(conteudo);
      // for (var value of bodyFormData.values()) {
      //   console.log(value);
      // }

      try {
        const resp = await apiProjectus.post(
          '/AtendimentoAnexoSalvar',
          bodyFormData,
          config
        );
        // console.log(resp.data)
        if (resp.data.mensagem == 'Erro ao salvar dados') {
          toast.dark(resp.data.isErro, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (err) {
        // console.log(err)
        toast.dark(err, {
          position: toast.POSITION.TOP_CENTER,
        });
        // showError(err)
      }
    }
  }

  async function excluiAnexo(valor) {
    const busca = caminhoAnexo.filter((ite) => ite.label != valor);
    const exclui = caminhoAnexo.filter((ite) => ite.label == valor);

    const bodyFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    // console.log(caminhoAnexo)
    bodyFormData.append('arquivo', exclui.label);
    try {
      const res = await apiProjectus.post(
        '/removeTempFile.htm',
        bodyFormData,
        config
      );
      if (res.data.acao == false) {
        // console.log('falhou', res.data)
        toast.dark('Não foi possível excluir o arquivo', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        setCaminhoAnexo(busca);
        // console.log(res.data.caminho)
        toast.success('Arquivo excluido', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.dark('Não foi possível excluir o arquivo', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  function selecionaAnexo(dados) {
    // console.log(dados);
    if (dados !== '' && dados !== null) {
      // console.log('passou');
      enviaAnexo(dados);
    }
  }

  async function enviaAnexo(dados) {
    const bodyFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    bodyFormData.append(
      'fileData',
      dados.target.files[0],
      dados.target.files[0].name
    );
    try {
      const res = await apiProjectus.post(
        '/uploadFile.htm',
        bodyFormData,
        config
      );
      if (res.data.isErro == true) {
        // console.log('falhou', res.data)
        toast.dark('Não foi possível enviar o arquivo', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        // let label = res.data.label;
        // const nome = label.split('.');
        const objeto = { path: res.data.caminho, label: res.data.label };
        // setCaminhoAnexo(res.data.caminho);
        setCaminhoAnexo([...caminhoAnexo, objeto]);
        // setNomeAnexo(res.data.label);
        // console.log(caminhoAnexo);
        toast.success('Arquivo enviado', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.dark('Não foi possível enviar o arquivo', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  async function verificaTokenSga(senhas) {
    // login sga
    const bodyFormSga = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    bodyFormSga.append('grant_type', 'password');
    bodyFormSga.append(
      'client_id',
      '1_45blj1ewgaecswo8k08w04o4wgw0ks8sgk0gsgw88oos0kg04g'
    );
    bodyFormSga.append(
      'client_secret',
      '3ey7lcoaxhicocow8ss8s8o8swc4888swks0k4kc4wogo4wk0w'
    );
    bodyFormSga.append('username', localStorage.getItem('mac_login'));
    bodyFormSga.append('password', '123456');
    try {
      const resp = await apiSga.post('/token', bodyFormSga, config); // produção
      if (resp) {
        if (resp.data.error) {
          // console.log(resp.data)
          toast.dark(resp.data.error_description, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          localStorage.setItem('mac_sgatoken', resp.data.access_token);
        }
      }
    } catch (err) {
      // showError(err)
      toast.dark('Não foi possível fazer login no SGA', {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    const configToken = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
      },
    };
    try {
      const encerra = await apiSga.post(
        '/atendimentos/encerraAtendimento',
        senhas,
        configToken
      );

      if (encerra.data.success == true) {
        const { numero } = senhas;
        toast.success(`Atendimento finalizado Nº ${numero}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.dark(
        'Não foi possível finalizar o atendimento,verifique se você tem algum atendimento em aberto',
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }

  async function encerraAtendimento(senhas) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('mac_sgatoken')}`,
      },
    };
    try {
      const encerra = await apiSga.post(
        '/atendimentos/encerraAtendimento',
        senhas,
        config
      );

      if (encerra.data.success === true) {
        const { numero } = senhas;
        toast.success(`Atendimento finalizado Nº ${numero}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      await verificaTokenSga(senhas);

      // toast.dark('Não foi possível finalizar o atendimento,verifique se você tem algum atendimento em aberto', {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }

  function alteraTipoSuporte(id) {
    setTipoSuporteId(id);
    // console.log(tipoAtendimentoId);
  }

  function alteraProblema(e) {
    setProblema(e.target.value);
    // console.log(problema);
  }
  function alteraSolucao(e) {
    setSolucao(e.target.value);
    // console.log(solucao);
  }
  function alteraObservacao(e) {
    setObservacao(e.target.value);
    // console.log(observacao);
  }

  function alteraPendenciaEspecifica() {
    setPendenciaEspecificaCliente(!pendenciaEspecificaCliente);
  }

  function alteraDesenvolvimento() {
    setDesenvolvimento(!desenvolvimento);
    setFinalizar(false);
    setResolvido(!resolvido);
  }

  function alteraFinalizar() {
    setFinalizar(!finalizar);
    setDesenvolvimento(false);
    setResolvido(true);
  }

  async function gravaAtendimento() {
    setBtnFinalizar(true);

    const bodyFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const token = localStorage.getItem('mac_token');
    const usuarioId = localStorage.getItem('mac_idusuario');

    bodyFormData.append('token', token);
    bodyFormData.append('usuario.id', usuarioId);

    bodyFormData.append('dataCadastro', dataInicio);
    bodyFormData.append('dataInicio', dataInicio);
    bodyFormData.append('horaInicio', horaInicio);

    if (finalizar) {
      const dataFim = moment(new Date()).format('DD/MM/YYYY');
      const horaFim = `${new Date().getHours()}:${
        new Date().getMinutes() < 10 ? '0' : ''
      }${new Date().getMinutes()}`;
      bodyFormData.append('dataFim', dataFim);
      bodyFormData.append('horaFim', horaFim);
      // console.log(dataInicio, horaInicio, horaFim, dataFim);
    }

    bodyFormData.append('pessoaJuridica.id', clienteId);
    bodyFormData.append('contato.id', contatoId);
    bodyFormData.append('nome_contato', contato);
    bodyFormData.append('tipoSuporte.id', tipoSuporteId);
    bodyFormData.append('tipoAtendimento.id', tipoAtendimentoId);

    // tb pendencia
    bodyFormData.append('descricaoProblema', problema);
    bodyFormData.append('descricaoSolucao', solucao);
    bodyFormData.append('observacao', observacao);
    bodyFormData.append('status', finalizar);
    bodyFormData.append('categoriaProblema.id', categoriaProblemaId);
    bodyFormData.append('projeto.id', projetoId);

    bodyFormData.append(
      'pendenciaEspecificaCliente',
      pendenciaEspecificaCliente
    );
    bodyFormData.append('desenvolvimento', desenvolvimento);
    bodyFormData.append('ativo', true);

    if (cliente === '' || clienteId === '') {
      toast.dark(`Selecione uma Serventia`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }

    if (contato === '') {
      toast.dark(`Selecione um Contato`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }
    if (projeto === '' || projetoId === '') {
      toast.dark(`Selecione um Projeto`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }
    if (categoriaProblema === '' || categoriaProblemaId === '') {
      toast.dark(`Selecione uma Categoria de Problema`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }
    if (problema === '') {
      toast.dark(`Informe um Problema`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }
    if (solucao === '' && finalizar == true) {
      toast.dark(`Informe a Solução do Problema`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return '';
    }

    setTimeout(() => {
      setBtnFinalizar(false);
    }, 500);

    try {
      const resp = await apiProjectus.post(
        '/AtendimentoSalvar',
        bodyFormData,
        config
      );

      if (resp.data.mensagem === 'Erro ao salvar dados') {
        toast.dark(resp.data.isErro, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      if (resp.data.mensagem === 'Dados Salvos com Sucesso') {
        const pendenciaId = resp.data.id;

        gravaAnexos(pendenciaId);
        // console.log(copiaPendencia);
        if (copiaPendencia === false) {
          encerraAtendimento(senha);
          // console.log(abaId);
          fechaAbaEncerra(abaId);
        }

        toast.success(resp.data.mensagem, {
          position: toast.POSITION.TOP_CENTER,
        });
        return abaId;
      }
      // toast.dark('Existem campos obrigatórios que devem ser preenchidos', {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      toast.dark(`${resp.data.mensagem}`, {
          position: toast.POSITION.TOP_CENTER,
         });
    } catch (err) {
      // console.log(err)
      toast.dark(err, {
        position: toast.POSITION.TOP_CENTER,
      });
      // showError(err)
    }
    return abaId;
  }

  function handleServentia(value,state){
      setCliente(value)
      setClienteId(state.id)
      setContato('')
      setContatoId('')
      setProjeto('')
      setProjetoId('')
      setCategoriaProblema('')
      setCategoriaProblemaId('')
      //console.log(value,state);
  }

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Card
        ativo={ativo}
        senha={senha}
        abaId={abaId}
        fechaAbaEncerra={fechaAbaEncerra}
      >
        <Form onSubmit={gravaAtendimento}>
          <Line>
            <Label>Formas de atendimento</Label>
          </Line>
          <Line>
            <BtnAtendimentos title="Telefone">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(2)}
                />
                <FiPhone color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="Celular">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(8)}
                />
                <FiSmartphone color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="Skype">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(1)}
                />
                <RiSkypeLine color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="Whatsapp">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(7)}
                />
                <FaWhatsapp color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="E-Mail">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(6)}
                />
                <FiMail color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="Acesso Remoto">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(3)}
                />
                <RiWindowLine color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
            <BtnAtendimentos title="Na Serventia">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(5)}
                />
                <FaLandmark color="#333" size={30} />
              </Label>
            </BtnAtendimentos>

            <BtnAtendimentos title="Chat SRI">
              <Label>
                <Input
                  type="radio"
                  name="tipoSuporteId"
                  onChange={() => alteraTipoSuporte(9)}
                />
                <BsChatDots color="#333" size={30} />
              </Label>
            </BtnAtendimentos>
          </Line>

          <Line>
            <div>
            <label>Serventia</label>
              <ReactAutocomplete
                wrapperStyle={{ display: 'flex', marginRight: '5px' }}
                items={dadosServentias}
                menuStyle={styleMenu}
                shouldItemRender={(item, value) => item.nome.toLowerCase().indexOf(value.toLowerCase()) > -1}
                getItemValue={(item) => item.nome}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? '#eee' : 'transparent',
                      padding: '5px 0px',
                    }}>
                    {item.nome}
                  </div>
                )}
                value={cliente || ''}
                onChange={(e) => setCliente(e.target.value)}
                onSelect={handleServentia}
              />
              </div>
              <div>
            <label>Contato</label>
              <ReactAutocomplete
                wrapperStyle={{ display: 'flex', marginRight: '5px' }}
                menuStyle={styleMenu}
                items={dadosContatoCliente}
                shouldItemRender={(item, value) =>
                  item.nome.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.nome}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? '#eee' : 'transparent',
                      padding: '5px 0px',
                    }}
                  >
                    {item.nome}
                  </div>
                )}
                value={contato || ''}
                onChange={(e) => setContato(e.target.value)}
                onSelect={(value, state) => (
                  setContato(value), setContatoId(state.id)
                )}
              />
              </div>
              <div>
            <label>Projeto</label>
              <ReactAutocomplete
                wrapperStyle={{ display: 'flex', marginRight: '5px' }}
                menuStyle={styleMenu}
                items={dadosProjetosCliente}
                shouldItemRender={(item, value) =>
                  item.descricao.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.descricao}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? '#eee' : 'transparent',
                      padding: '5px 0px',
                    }}
                  >
                    {item.descricao}
                  </div>
                )}
                value={projeto || ''}
                onChange={(e) => setProjeto(e.target.value)}
                onSelect={(value, state) => (
                  setProjeto(value), setProjetoId(state.id)
                )}
              />
            </div>
            <div>
            <label>Categoria de Problema</label>
              <ReactAutocomplete
                wrapperStyle={{ display: 'flex', marginRight: '0px' }}
                menuStyle={styleMenu}
                items={dadosCategoriaProblema}
                shouldItemRender={(item, value) =>
                  item.descricao.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                placeholder="Categoria Problema..."
                getItemValue={(item) => item.descricao}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? '#eee' : 'transparent',
                      padding: '5px 0px',
                    }}
                  >
                    {item.descricao}
                  </div>
                )}
                value={categoriaProblema || ''}
                onChange={(e) => setCategoriaProblema(e.target.value)}
                onSelect={(value, state) => (
                  setCategoriaProblema(value), setCategoriaProblemaId(state.id)
                )}
              />
            </div>
          </Line>
          <Input
            name="problema"
            value={problema}
            onChange={alteraProblema}
            multiline
            rows={2}
            placeholder="Descrição do problema..."
          />
          <Input
            name="solucao"
            value={solucao}
            onChange={alteraSolucao}
            multiline
            rows={2}
            placeholder="Descrição da solução..."
          />
          <Input
            name="observacao"
            value={observacao}
            onChange={alteraObservacao}
            multiline
            rows={2}
            placeholder="Observação..."
          />
          <InputAnexo>
            Arraste aqui os arquivos para enviar
            <Input
              name="anexo"
              onChange={selecionaAnexo}
              type="file"
              placeholder="Anexar arquivo"
            />
          </InputAnexo>
          <Line>
            <If teste={caminhoAnexo.length > 0}>
              {caminhoAnexo.map((item) => {
                return (
                  <BoxImagem key={item.label}>
                    {item.label}
                    <FaTimes
                      title="excluir"
                      onClick={() => excluiAnexo(item.label)}
                      color="#000000"
                      size={22}
                    />
                  </BoxImagem>
                );
              })}
            </If>
          </Line>
          <Line>
            <Label>
              <Check
                checked={pendenciaEspecificaCliente}
                onChange={alteraPendenciaEspecifica}
                name="pedenciaEspecificaCliente"
              />
              Pendência específica do cliente
            </Label>
            <Label>
              <Check
                checked={desenvolvimento}
                onChange={alteraDesenvolvimento}
                name="desenvolvimento"
              />
              Gerar pendência
            </Label>
            <Label>
              <Check
                checked={finalizar}
                onChange={alteraFinalizar}
                name="finalizar"
              />
              Atendimento resolvido
            </Label>
          </Line>
          <Line style={{ justifyContent: 'flex-end' }}>
            <BtnAzul onClick={() => setCopiaPendencia(true)} type="submit">
              Salvar pendência e manter cópia
            </BtnAzul>
            <BtnSubmit onClick={() => setCopiaPendencia(false)} type="submit">
              Finalizar Atendimento
            </BtnSubmit>
          </Line>
        </Form>
      </Card>
    </>
  );
}

export default memo(Aba);
