import React from "react"

import "./InfoBarSRI.css"

import onlineIcon from "../../imgs/onlineIcon.png"
import closeIcon from "../../imgs/closeicon.png"

const InforBarSRI = () => (
    <div className="infoBar">
        <div className="leftInnerContainer">
            <img className="onlineIcon" src={onlineIcon} alt="online image" />
            <h3 style={{width: "224px"}}>CHAT SUPORTE MACTUS</h3>
        </div>
        {/* <div className="rightInnerContainer">
            <a href="/"><img src={closeIcon} alt="close" /></a>
        </div> */}
    </div>

)

export default InforBarSRI
