import React from "react"

import "./InfoBar.css"
import closeIcon from "../../imgs/closeicon.png"
import onlineIcon from "../../imgs/onlineIcon.png"
import { AiOutlineCloseCircle, AiFillCloseCircle, AiOutlineClose,AiOutlinePoweroff } from 'react-icons/ai'

const InforBar = ({ cliente, disconnectChat, closeChat}) => (
    <div className="infoBar">
        <div className="leftInnerContainer">
            <img className="onlineIcon" src={onlineIcon} alt="online image" />
            <h3>{cliente}</h3>
        </div>
        <div className="rightInnerContainer">

            <a title={"Finalizar a conversa"} onClick={(event)=> disconnectChat(event)}>Finalizar <AiOutlinePoweroff style={{marginBottom:"-3.5px"}} size={18}/></a>
            <button title={"Sair da conversa"}  onClick={(event)=>closeChat(event)}>Sair <AiFillCloseCircle style={{marginBottom:"-3.5px"}} size={18}/></button>
        </div>
    </div>

)

export default InforBar
