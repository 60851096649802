import axios from 'axios';

const endereco = window.location.host;
let URL;
// console.log(endereco);
if (endereco === '192.168.1.166' || endereco === 'localhost:3000') {
  URL = 'http://192.168.1.166:8081/api'; // dev
  // URL = 'http://192.168.1.166/api'; //dev
  // URL = 'https://macatendimento.mactus.online/api'; //produção
} else {
  // URL = 'http://192.168.2.53:8081/api'; //produção
  URL = 'https://macatendimento.mactus.online/api'; // produção
}
const apiSga = axios.create({
  baseURL: URL,
});

export default apiSga;
