import styled from 'styled-components';

export const Container = styled.div`
    display:${props => (props.visible ? "none" : "flex")};
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:220px;
    padding:10px;
    background:var(--site);
    flex-wrap:wrap;
    height:100%;
    width:220px;
    animation:fadeIn 0.3s ease-in-out;

    @media (max-width:720px){
      position:absolute;
      z-index:1000;
      margin:0px;
      left:${props => (props.visible ? "0" : "-220")}px;
    }
`;

export const Logo = styled.div`
  width:100%;
  height:auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-bottom:20px;

  h1{
    color: var(--branco);
    font-size:16px;
    text-transform:uppercase;
  }

  img{
    filter:grayscale(1) brightness(10);
    margin-bottom:10px;
  }
`;

export const Avatar = styled.div`
  width:100%;
  height:auto;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
`;


