import axios from 'axios';

const endereco = window.location.host;
let URL;
// console.log(endereco);
if (endereco === '192.168.1.166' || endereco === 'localhost:3000') {
  URL = 'http://192.168.1.166:8080/Projectus/api'; // dev
  // URL = 'http://192.168.1.166:8080/Projectus/api'; //dev
  // URL = 'https://projectus.mactus.com.br/Projectus/api'; //produção
} else {
  // URL = 'http://192.168.2.53:8080/Projectus/api'; //produção
  URL = 'https://projectus.mactus.com.br/Projectus/api'; // produção
}

const apiProjectus = axios.create({
  baseURL: URL,
});

export default apiProjectus;
