import React, { useState, useEffect } from "react"

import "./Input.css"
import sendIcon from "../../imgs/onlineIcon.png"
import { AiOutlineSend, AiFillPicture } from "react-icons/ai"
import Dropzone from "react-dropzone"
import { TiMicrophone } from 'react-icons/ti';
import Microfone from "../Microfone/index.js"







const Input = ({ message, setMessage, sendMessage, sendFile, stateRecord, startRecording, stopRecording, cancelRecording }) => {

return(
<div style={{display:"flex"}} className={"inputBox"}>

    {stateRecord !="true" ?
    <>
    <input className="input"
    style={{marginRight: 0}}
    type="text"
    placeholder="Digite sua mensagem"
    value={message} onChange={(event) => setMessage(event.target.value)}
    onKeyPress={event => event.key === "Enter" ? sendMessage(event) : null}
  >
  </input>

  <label id="labelUploadImg" htmlFor="upImg" >
    <AiFillPicture className="sendMessageIcon" />
  </label>

  <input id="upImg" className="uploadImageBtn" accept="image/x-png,image/gif,image/jpeg,image/jpg" type="file" onChange={(event) => sendFile(event)} />

  <Microfone stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}/>

  <button className="sendButton" onClick={(event) => sendMessage(event)}><AiOutlineSend className="sendMessageIcon" /></button>
    </>
  :
  <>
  <>
  <div style={{width:"80%"}}></div>
  <Microfone stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}/>
  </>
  </>
  }


</div>
)}

export default Input
