import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import PerfectScrollbar from 'react-perfect-scrollbar';
export const Colum = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
`;
export const Line = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:center;
`;

export const Toasts = styled(ToastContainer)`
  min-height: auto;
  padding: 10px;
  border-radius: 6px;

`;

export const Abas = styled.div`
  padding:10px;
  width:auto;
  height:auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background:var(${props => props.ativo ? "--branco" : "--cinza-claro"});
  color:var(${props => props.ativo ? "--cinza-escuro" : "--branco"});
  border-radius:15px 15px 0px 0px;
  margin:0px 10px 0px 0px;
  border-top:1px solid var(--cinza-claro);
  border-left:1px solid var(--cinza-claro);
  border-right:1px solid var(--cinza-claro);
  cursor:pointer;
  transition:all 0.3s ease-in-out;

  h3{
    font-size:14px;
    padding:5px 0px;
  }
`;

export const CloseAba = styled.div`
  margin-left:10px;
`;

export const BtnDefault = styled.button`
    background:var(--verde);
    font-size:13px;
    color:var(--branco);
    padding:15px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
`;

export const BtnSubmit = styled.button`
    background:var(--verde);
    font-size:13px;
    color:var(--branco);
    padding:15px;
    border-radius:6px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
`;

export const BtnSelect = styled.button`
    border-radius:6px;
    background:var(--branco);
    padding:10px 15px;
    border:1px solid var(--cinza-claro);
    flex:1;
    justify-content:space-between;
    flex-direction:row;
    align-items:center;
    margin-right:10px;
    height:auto;
    margin-bottom:10px;
`;

export const BtnAtendimentos = styled.button`
    border-radius:30px;
    background:var(--branco);
    padding:10px 15px;
    border:1px solid var(--cinza-claro);
    flex:1;
    justify-content:space-between;
    flex-direction:row;
    align-items:center;
    width:auto;
    margin-right:10px;
    margin-bottom:15px;
`;

export const BtnNovoAtendimento = styled.button`
    background:var(--verde);
    font-size: 12px;
    color: var(--branco);
    padding: 20px 10px;
    border-radius:30px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
    margin-bottom:10px;
    box-shadow: 0 5px 0 #007f41;
    transition: all .1s linear;

    :active {
      box-shadow: 0 2px 0 #007f41;
      transform: translateY(3px);
    }


`;

export const Label = styled.label`
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:flex-start;
  align-items:center;
  display:flex;
  margin-bottom:5px;
  margin-left:10px;
  cursor:pointer;
`;

export const Scroll = styled(PerfectScrollbar)`
  width:100%;
  max-height:100%;
  padding:0px 5% 0px 5%;

`;

export const BtnMinMax = styled.button`
    background:rgb(0, 55, 82,1);
    font-size: 16px;
    color: var(--branco);
    padding: 10px 5px;
    border-radius:20px 0 0 20px;
    border:none;
    text-transform:uppercase;
    font-weight:700;
    height: 65px;
    @media (max-width:960px){
      display: ${props => props.estado ? "true" : "none"};
    }
`;

export const BoxMinimizado = styled.div`
  width:60px;
  padding:5px;
  height:550px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  background: var(--branco);
  border-radius:0px 6px 6px 6px;
  box-shadow:2px 4px 10px rgb(0, 55, 82,0.8);
  border:2px solid rgb(0, 55, 82,0.8 );
  margin: 0 0 0 0;
  @media (max-width:960px){
    border-radius:6px;
  }
  ul{
    list-style-type:none;
    width:100%;
    padding:0px 0px 0px 0px;

    .itemListPendente{
      padding: 1.5px 0px 0px 1.5px;
      margin-bottom:5px;
      width:42px;
      text-transform: uppercase;
      cursor:pointer;
      transition:all 0.1s ease-in-out;
      border-radius:6px;
      background: rgb(136, 0, 0,0.1);
      border:2px solid rgb(136, 0, 0,0.4);
    }
    .itemListAndamento{
      padding: 1.5px 0px 0px 1.5px;
      margin-bottom:5px;
      width:42px;
      text-transform: uppercase;
      cursor:pointer;
      transition:all 0.1s ease-in-out;
      border-radius:6px;
      background: rgb(0, 0, 255,0.1);
      border:2px solid rgb(0, 0, 255,0.4);
    }
    .itemListFinalizado{
      display: none !important;
    }

  }
`;


export const BoxLigacoes = styled.div`
  width:300px;
  /* padding:15px; */
  height:550px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  background:var(--branco);
  border-radius:0px 6px 6px 6px;
  box-shadow:2px 4px 10px rgb(0, 55, 82,1);
  border:2px solid rgb(0, 55, 82,1);
  margin: 0 0 0 0;

  .bodyAtendimentos{
    padding:15px;
  }


  #titleList{
    display: flex;
    background: rgb(0, 55, 82,0.9);
    border-bottom: 2px solid rgb(0, 55, 82,1);
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    h2{
      font-size:14px;
      margin-bottom:5px;
      color:var(--branco);
      margin-right: 5px;
      font-weight: 500;
    }
    #numPendentes{
      text-align: center;
      border-top:2px solid #ffffff;
      border-radius: 6px 0px 0px 6px;
      border-left:2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      box-shadow: 0px 10px -14px 14px #FFF;
      padding: 7px 0px 0px 0px;
      background: rgb(136, 0, 0,0.7);
      font-size:16px;
      margin-bottom:7px;
      color:#ffffff;
      font-weight: 700;
      width: 20%;
      height: 100%
    }
    #numAndamentos{
      text-align: center;
      border-top:2px solid #ffffff;
      border-left:2px solid #ffffff;
      border-right:2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      padding: 7px 0px 0px 0px;
      background: rgb(0, 0, 255,0.5);
      font-size:16px;
      color:#ffffff;
      margin-bottom:7px;
      font-weight: 700;
      width: 20%;
      height: 100%
    }
    #numFinalizados{
      text-align: center;
      border-top:2px solid #ffffff;
      border-right:2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      border-radius: 0px 6px 6px 0;
      padding: 7px 0px 0px 0px;
      background: rgb(0, 136, 0,0.7);
      font-size:16px;
      margin-bottom:7px;
      color:#ffffff;
      font-weight: 700;
      width: 20%;
      height: 100%
    }
  }

  #listDivisor{
    display: flex;


  }

  ul{
    list-style-type:none;
    width:100%;
    padding:0px 0px 0px 0px;

    .itemListPendente{
      padding:10px;
      margin-bottom:5px;
      width:100%;
      text-transform: uppercase;
      cursor:pointer;
      transition:all 0.1s ease-in-out;
      border-radius:6px;
      background: rgb(136, 0, 0,0.1);
      color:var(--cinza-escuro);
      #statusAtendimento{
        display: flex;
        justify-content: space-between;

        font-size: 10px;
        margin-right:7px;

      }
      #dadosAtendimento{

        display: inline-block;
        word-break: break-all;
        padding: 0;
        margin-bottom:5px;
        font-size: 12px;
      }
      :hover{
        color: var(--preto);
        background:rgb(136, 0, 0,0.4);
      }


    }
    .itemListAndamento{
      padding:10px;
      margin-bottom:5px;
      width:100%;
      text-transform: uppercase;
      cursor:pointer;
      transition:all 0.1s ease-in-out;
      border-radius:6px;
      background: rgb(0, 0, 255,0.1);
      color:var(--cinza-escuro);
      #statusAtendimento{
        display: flex;
        justify-content: space-between;

        font-size: 10px;
        margin-right:7px;

      }
      #dadosAtendimento{

        display: inline-block;
        word-break: break-all;
        padding: 0;
        margin-bottom:5px;
        font-size: 12px;
      }
      :hover{
        color: var(--preto);
        background:rgb(0, 0, 255,0.4);
      }


    }
    .itemListFinalizado{
      display:none !important;
    }


  }
`;

// padding:10px;
      // margin-bottom:5px;
      // width:100%;
      // text-transform: uppercase;
      // cursor:pointer;
      // transition:all 0.1s ease-in-out;
      // border-radius:6px;
      // background:rgb(0, 128, 0,0.1);
      // color:var(--cinza-escuro);
      // #statusAtendimento{
      //   display: flex;
      //   justify-content: space-between;

      //   font-size: 10px;
      //   margin-right:7px;

      // }
      // #dadosAtendimento{

      //   display: inline-block;
      //   word-break: break-all;
      //   padding: 0;
      //   margin-bottom:5px;
      //   font-size: 12px;
      //   width: 100%
      // }
      // :hover{
      //   color: var(--preto);
      //   background:rgb(0, 128, 0,0.4);
      // }
