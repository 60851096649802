import React from "react"

import "./InputSRI.css"
import Microfone from "../Microfone/index.js"
import sendIcon from "../../imgs/onlineIcon.png"

import{ AiOutlineSend,AiFillPicture} from "react-icons/ai"
import Dropzone from "react-dropzone";




const InputSRI = ({message,setMessage,sendMessage,sendFile, stateRecord, startRecording, stopRecording,cancelRecording }) =>{


return (
  <div className={"inputBoxSRI"}>
       {stateRecord !="true" ?

        <>
           <input className="inputSRI"
             style={{marginRight: 0}}
             placeholder="Digite sua mensagem"
             value={message} onChange={(event)=>setMessage(event.target.value)}
             onKeyPress={event => event.key ==="Enter"?sendMessage(event): null}
           >
           </input>


          <label id="labelUploadImgSRI"  htmlFor="upImg" >
          <AiFillPicture className="sendMessageIcon" />
          </label>
  <input id="upImg" name="upImg" className="uploadImageBtn" accept="image/x-png,image/gif,image/jpeg" type="file" onChange={(event)=> sendFile(event)} />

  {/* <Microfone stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}/> */}

  <button className="sendButtonSRI" onClick={(event)=> sendMessage(event)}><AiOutlineSend className="sendMessageIcon" /></button>


        </>
      :
    <>
      <div style={{width:"80%"}}></div>
      <Microfone stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording}/>
    </>
      }
  </div>

  )
}
export default InputSRI
