
import React from "react"
import ModalImage from "react-modal-image";
import "./Message.css"
//import sendIcon from "../../imgs/onlineIcon.png"


const Message = ({ message: { user, text, data, hora }, name }) => {
    let isSentByCurrentUser = false;
    let isImage = false;
    let isAudio = false;
    const trimmedName = name.trim().toLowerCase();
    const trimmedUser = user.trim().toLowerCase()
    if (user === trimmedName) {
        isSentByCurrentUser = true
    }

    if (text.substring(0, 11) === 'data:image/') {
        isImage = true;
    }
    if (text.substring(0, 11) === 'data:audio/') {
      isAudio = true;
  }
    var userCaptalized = user.charAt(0)!="▪"? user.charAt(0).toUpperCase() + user.slice(1): user.slice(0,1)+ user.charAt(2).toUpperCase() + user.slice(3);;
    var nameCapitalized = trimmedName.charAt(0)!="▪"? trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1): trimmedName.slice(0,1)+ trimmedName.charAt(2).toUpperCase() + trimmedName.slice(3);
    return (
    trimmedUser !== "admin"?
        isSentByCurrentUser ?
            (
                isImage ?
                    (
                        <div className="messageContainer justifyEnd">
                            <p className="sentText pr-10">{nameCapitalized} {hora} {data}</p>
                            <div className="currentMessageBox">
                            <div className="imageBox">
                            <ModalImage
                            small={text}
                            large={text}
                            hideZoom={true}
                            alt="Imagem"
                            hideDownload={true}
                            />
                              {/* <img className="imageChat"  src={text}></img> */}
                            </div>
                            </div>
                        </div>

                    )
                    : isAudio ?
                    <div className="messageContainer justifyEnd">
                            <p className="sentText pr-10">{nameCapitalized} {hora} {data}}</p>
                            <div className="currentMessageBox">

                           <audio src={text} controls style={{width: "200px", display:"block"}}/>
                            </div>
                    </div>
                    :
                    (
                        <div className="messageContainer justifyEnd">
                            <p className="sentText pr-10">{nameCapitalized} {hora} {data}</p>
                            <div className="currentMessageBox">
                                <p className="messagesText">{text}</p>
                            </div>
                        </div>
                    )

            )
            :
            (
                isImage ?
                    (
                        <div className="messageContainer justifyStart">
                        <div className="messageBox">
                        <ModalImage
                            small={text}
                            large={text}
                            hideZoom={true}
                            alt="Imagem"
                            hideDownload={true}
                            />
                        </div>
                        <p className="sentText pl-10">{userCaptalized} {hora} {data}</p>
                    </div>

                    )
                    : isAudio?
                    (
                      <div className="messageContainer justifyStart">
                        <div className="messageBox">
                        <audio src={text} controls style={{width: "200px", display:"block"}}/>
                        </div>

                    </div>
                    )
                    :
                    (
                      <div className="messageContainer justifyStart">
                        <div className="messageBox">
                            <p className="messagesText colorDark">{text}</p>
                        </div>
                        <p className="sentText pl-10">{userCaptalized} {hora} {data}</p>
                    </div>
                    )

            )
        :
        (
            isImage ?
                (
                    <div className="messageContainer justifyStart">
                    <div className="messageBox backgroundLight">
                    <ModalImage
                            small={text}
                            large={text}
                            hideZoom={true}
                            alt="Imagem"
                            hideDownload={true}
                            />
                    </div>
                    <p className="sentText pl-10">{userCaptalized} {hora} {data}</p>
                </div>

                )
                :
                isAudio?
                (
                  <div className="messageContainer justifyCenter">
                    <div className="messageBox">
                    <audio src={text} controls style={{width: "200px", display:"block"}}/>
                    </div>

                </div>
                )
                :
                (
                    <div className="messageContainer justifyCenter">
                    <div className="messageAdminBox">
                        <p className="messageAdminText colorDark">{text}</p>
                    </div>

                </div>
                )

        )

    )

}
export default Message

