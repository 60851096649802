import React, { useState, useEffect,useLayoutEffect } from "react"
import queryString from "query-string"
import io from "socket.io-client"
import Dropzone from "react-dropzone"
import TextareaAutosize from 'react-textarea-autosize';
import { useBeforeunload } from 'react-beforeunload';
import "./Chat.css"
import InfoBar from "../../components/InfoBar/InfoBar"
import Input from "../../components/Input/Input"
import Messages from "../../components/Messages/Messages"



import Compressor from 'compressorjs';

let socket;
const suporte = true
const sentBy = 'suporte'

const Chat = ({ location }) => {
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [hora, setHora] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([{user: "admin", text:`CHAT INICIADO`}]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileBase64String, setFileBase64String] = useState("");
    const [cliente, setCliente] = useState("");
    const [audioURL, setAudioURL] = useState(",,,,,");
    const [stateRecord, setStateRecord] = useState("false");
    const [recorder, setRecorder] = useState(null);


    const ENDPOINT = "https://macatendimento.mactus.online"
    var trimmedRoom = `${room.trim().toLowerCase()}`



    useLayoutEffect(async () => {
        const { name, room, hora, cliente } = queryString.parse(location.search)

        socket = io(ENDPOINT, {
            path: '/server-chat'
          })
        console.log(socket)
        setName(`${name}`);
        setRoom(`${room}`);
        setHora(`${hora}`)
        setCliente(`${cliente}`)
        console.log(name, room)
        await socket.emit("join", { name, room, suporte, sentBy }, () => {
            console.log({ name, room, suporte, sentBy })
        })



        return  () => {
            socket.off();
        }
    },[]);


    useEffect(() => {
        socket.on('message', (message) => {

            setMessages([...messages, message])

        })

        socket.on('previousMessage', (message) => {

            var auxMessage = []
            for (let i = 0; i < message.length; i++) {
                    auxMessage.push({user: message[i].nome, text: message[i].mensagem, hora: message[i].hora, data: message[i].data })
            }
           setMessages([...messages,...auxMessage])

        })

        return () => {
            socket.off()
        }

    }, [messages])


    useEffect(() => {
        console.log(selectedFile)
        encodeFileBase64(selectedFile[0])

    }, [selectedFile])

    useEffect(() => {
        var imgBase64
        if (fileBase64String != "") {

            imgBase64 = fileBase64String
            console.log("Tamanho Base64String" + imgBase64.length)
            try {
                socket.emit('sendMessage', {
                    lastMessage: imgBase64,
                    chatID: room,
                    nameUser: name
                }, () => {
                    console.log("Arquivo enviado")
                    setFileBase64String('')
                })
            } catch (error) {
                console.log(error)
            }
        }


    }, [fileBase64String])

    useEffect(() => {

        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
          if (stateRecord==="true") {

            requestRecorder().then(setRecorder).catch((err)=>console.log(err));
          }
          return;
        }

        // Manage recorder state.
        if (stateRecord==="true" && recorder) {
          if(recorder.state=="inactive"){
            recorder.start();
            console.log(recorder.state)
          }
        } else if(recorder){
          if(recorder.state=="recording"){
            recorder.stop();
          }
        }

        // Obtain the audio when ready.
        const handleData = e => {
        if(stateRecord==="cancel"){
          console.log("Audio cancelado")
        }else{
          var reader = new FileReader();
          reader.readAsDataURL(e.data);
          reader.onloadend = function() {
          var base64data = reader.result;

          socket.emit('sendMessage', {
            lastMessage: base64data,
            chatID: room,
            nameUser: name
        }, () =>  console.log(base64data))
        }
        }

      //  setAudioURL(URL.createObjectURL(e.data));
        };

        if(recorder){
          recorder.addEventListener("dataavailable", handleData);
        }


        // setRecorder(null)

      return () => {
        if(recorder){
            recorder.removeEventListener("dataavailable", handleData)
          }}

      }, [recorder, stateRecord]);


    const sendMessage = (event) => {

        event.preventDefault();
        if (message) {
            socket.emit('sendMessage', {
                lastMessage: message,
                chatID: room,
                nameUser: name,

            }, () => setMessage(''))
        }
    }


    const onFileChange = async (e) => {
        console.log(e.target.files[0])
        console.log(e.target.files)
        setSelectedFile(e.target.files);
        //console.log(file)


    }

    const encodeFileBase64 = (file) => {
        if (file) {
            new Compressor(file, {
                maxWidth: 300,
                quality: 1,
                success(result) {

                    console.log(result)
                    var reader = new FileReader();

                    console.log("If File")
                    reader.readAsDataURL(result)
                    reader.onload = () => {
                        var Base64 = reader.result
                        setFileBase64String(Base64)
                    };
                    reader.onerror = function (error) {
                        console.log("Error Encode Base64: ")
                        console.log(error)
                    }



                },
                error(err) {
                    console.log(err.message);
                },
            });

        }

    }


     const disconnectChat = (event) =>{

      var answer = window.confirm("Tem certeza que deseja finalizar o Atendimento?")
      if (answer) {
        socket.emit("exit",({ room: room.trim().toLowerCase(), name: name.trim().toLowerCase(), suporte: suporte, hora: hora}))
        // socket.disconnect().then(console.log("desconectou!")).catch((err)=>{console.log("Ocorreu um erro ao desconectar: "+err)})
         window.open("about:blank", "_self");
         window.close();
      }


    }

    const closeChat = (e)=>{
        socket.emit("closeChat",({ room: room.trim().toLowerCase(), atendente: name.trim().toLowerCase(), cliente: cliente, hora: hora.trim().toLowerCase()}))
        window.open("about:blank", "_self");
        window.close();
    }

    const startRecording = (e) => {
        console.log("START RECORD")
        setStateRecord("true");

  };

  const stopRecording = (e) => {
    console.log("STOP RECORD")
    setStateRecord("false");
//     var reader = new FileReader();
//     reader.readAsDataURL(audioURL);
//     reader.onloadend = function() {
//     var base64data = reader.result;
//     console.log(base64data);
//  }
};

const cancelRecording = (e) => {
  setStateRecord("cancel")
};

async function requestRecorder() {
    try{
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    console.log(stream)
    return new MediaRecorder(stream);
    }
    catch(err){
       window.alert("Não foi possível identificar o microfone")
       console.log("Não foi possível identificar o microfone")
       setStateRecord(false)

    }
  }


function horaAtual() {
    var data = new Date()
    var min = data.getMinutes()
    var hora = data.getHours()
    var segs = data.getSeconds()

    return `${`${hora}`.length > 1 ? hora : "0" + hora}:${`${min}`.length > 1 ? min : "0" + min}`
}

function diaAtual() {
    var data = new Date()
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano = data.getFullYear();
    return `${dia}/${mes+1}/${ano}`
}



    return (
        <div className="outerContainer">
            <div className="container">
                <InfoBar cliente={cliente} disconnectChat={disconnectChat} closeChat={closeChat}
                />
                <Messages messages={messages} name={name} />
                <Input message={message} setMessage={setMessage} sendMessage={sendMessage} sendFile={onFileChange}
                stateRecord={stateRecord} startRecording={startRecording} stopRecording={stopRecording} cancelRecording={cancelRecording} />

                {/* <input value={message} onChange={(event)=>setMessage(event.target.value)}
                onKeyPress={event => event.key ==="Enter"?sendMessage(event): null} ></input> */}
            </div>

        </div>
    )
}

export default Chat;
