import React from 'react';
import apiProjectus from '../../services/apiProjectus';
import apiSga from '../../services/apiSga';
import axios from 'axios';
import history from '../../services/history';
import logo from '../../imgs/logo.png';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Wrapper, Container, H1, } from './styles';

const schema = Yup.object().shape({
  login: Yup.string().required('Campo obrigatório'),
  senha: Yup.string().min(6, 'Mínimo 6 caracteres').required('Campo obrigatório'),
});


function Login() {
  //const [info, setInfo] = useState('');
  //const [visible, setVisible] = useState(false);

  async function loginUsuario(data) {

    const login = data.login
    const senha = data.senha
    //console.log(login,senha);

    //login projectus
    const bodyFormData = new FormData()
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    //dados projectus
    bodyFormData.append('login', login);
    bodyFormData.append('senha', senha);
    try {

      const resp = await apiProjectus.post('/Login.htm', bodyFormData, config);
      if (resp) {
        if (resp.data.isErro === true) {
          //console.log(resp.data)
          toast.dark(resp.data.mensagem, {
            position: toast.POSITION.TOP_CENTER,

          });
        } else {
          axios.defaults.headers.common['token'] = resp.data.token
          //salva os dados do usuário logado
          localStorage.setItem('mac_login', login);
          localStorage.setItem('mac_usuario', resp.data.nomeUsuario)
          localStorage.setItem('mac_idusuario', JSON.stringify(resp.data.idUsuario))
          localStorage.setItem('mac_token', resp.data.token);
          localStorage.setItem('mac_logged', true);
          //console.log(resp.data)
          history.push('/dashboard');
        }

      }
    } catch (err) {
      //showError(err)
      toast.dark('Não foi possível fazer login no Projectus', {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //login sga
    const bodyFormSga = new FormData()
    bodyFormSga.append('grant_type', 'password');
    bodyFormSga.append('client_id', '1_45blj1ewgaecswo8k08w04o4wgw0ks8sgk0gsgw88oos0kg04g');
    bodyFormSga.append('client_secret', '3ey7lcoaxhicocow8ss8s8o8swc4888swks0k4kc4wogo4wk0w');
    bodyFormSga.append('username', login);
    bodyFormSga.append('password', '123456');
    try {

      //const resp = await apiSga.post('/api/token', bodyFormSga, config); //desenvolvimento
      const resp = await apiSga.post('/token', bodyFormSga, config); //produção
      if (resp) {
        if (resp.data.error) {
          //console.log(resp.data)
          toast.dark(resp.data.error_description, {
            position: toast.POSITION.TOP_CENTER,

          });
        } else {
          //axios.defaults.headers.common['token'] = resp.data.token
          localStorage.setItem('mac_sgatoken', resp.data.access_token)
        }
      }

    } catch (err) {
      //showError(err)
      toast.dark('Não foi possível fazer login no atendimento', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }


  return (
    <Wrapper>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container>
        <img alt="" src={logo} height={80} />
        <H1>Mac Atendimento</H1>
        <Form schema={schema} onSubmit={loginUsuario}>
          <Input name="login" type="text" placeholder="Usuário" />
          <Input name="senha" type="password" placeholder="Senha" />
          <button type="submit">Acessar</button>
        </Form>
      </Container>
    </Wrapper>
  );
}

export default Login;
