import axios from 'axios';

const endereco = window.location.host;
let URL;
// console.log(endereco);
if (endereco === '192.168.1.166' || endereco === 'localhost:3000') {
  URL = 'http://192.168.1.166:3001/api'; // dev

} else {

  URL = 'http://192.168.2.53:3001/api'; // produção
}

const apiMacGestao = axios.create({
  baseURL: URL,
});

export default apiMacGestao;
