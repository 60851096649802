import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./Join.css";

const Join = () => {
    const [room, setRoom] = useState('');
    const [name, setName] = useState('');


    return (

        <div className="joinOuterContainer">
            <div className="joinInnerContainer">
                <h1 className="heading">Chat Suporte</h1>
                <div><input placeholder="Digite seu nome" className="joinInput" type="text" onChange={(event)=>setName(event.target.value)}/> </div>
                <div><input placeholder="Digite o nome da Sala" className="joinInput" type="text" onChange={(event)=>setRoom(event.target.value)}/></div>
                
                <Link onClick={event => (!name || !room) ? event.preventDefault() : null  } to={`/chat?name=${name}&room=${room}`}>
                <button className="button mt-20" type="submit">Entrar</button>
                </Link>

            </div>
        </div>

    )

}

export default Join;

